@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

*::-webkit-scrollbar {
  width: 0.3em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(17 24 39 / var(--tw-bg-opacity));
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.primary-color {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  font-family: "Manrope", sans-serif;
}

* {
  box-sizing: border-box;
}

#root,
body,
html {
  height: 100%;
  width: 100%;
}

.eyeIcon {
  float: right;
  margin-right: 10px;
  margin-top: -44px;
  position: relative;
  z-index: 2;
  --tw-bg-opacity: 1;
  color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.timeArrivedDone {
  background: #6a1212 !important;
  animation: pulse 5s infinite !important;
}

.emergency {
  fill: #6a1212 !important;
  stroke: #fff;
  animation: pulse 5s infinite !important;
}

.change-avatar-input {
  position: absolute;
  left: -9999px;
}

.avatar-label:hover {
  background-color: #6b7280;
  border-color: #6b7280;
}

.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.mention-container {
  position: relative;
}

.mention-options {
  position: absolute;
  bottom: 100%; /* Display above the textarea */
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}

.mention-options li {
  padding: 4px;
  cursor: pointer;
}

.mention-options li:hover {
  background-color: #6193f8;
}

.chart-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.chart-wrapper {
  width: 100%;
  height: 500px;
}

.images-container {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  max-width: 250px;
  min-height: 200px;
  position: relative;
}

.images-child-ahref {
  position: absolute;
  top: 21px;
  left: 43px;
}

.cancel-container {
  position: absolute;
  top: -11px;
  right: -11px;
}

.cancel-container button {
  border: 2px solid #1f2937;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #1f2937;
  transition: all 0.3s ease-out;
}

.cancel-container button:hover {
  background-color: #1f2937;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.request-call-topbar {
  display: flex;
  margin-bottom: 20px;
}

.grid-child {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
  border-radius: 11px;
  min-height: 43px;
  text-align: center;
}

.topbar-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 20px;
}

/************* Export Icon *************/
.export-btn {
  background-color: transparent;
  color: #ffffff;
  font-size: 1.5rem;
}
/************* END/ Export Icon *************/

/************* Notification  *************/
.notification-dropdown {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 855px;
  max-width: 350px;
}

.notification-btn {
  cursor: pointer;
}

.notification-btn:hover {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity)) !important;
}

/************* END/ Notification  *************/
@media only screen and (max-width: 1250px) {
  .request-call-topbar {
    flex-direction: column;
  }

  .topbar-info {
    margin-left: 0;
    margin-top: 20px;
    gap: 10px;
  }
}



#select-tech-div {
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  color: #FFFFFF;
  position: absolute;
  width: 100%;
  max-height: 120px;
  overflow: hidden scroll;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-width: 1px;
  border-top: unset;
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
  /* display: none; */
}

#select-tech-ul {
  list-style: none; 
  padding: 0px; 
  margin: 0px;
}
#select-tech-ul li {
  padding: 5px 5px;
}

#select-tech-ul li button {
  font-size: 12px;
}

#select-tech-ul li:hover {
  background-color: #6193f8;
  cursor: pointer;
}